import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

/** 회원별 예약 내역 리스트 */
const useInfinityHistory = (params: BookingHistoryParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<BookingHistoryResponse, BookingHistoryParams>({
    url: `/api/history`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['booking', 'history', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityHistory;

export type BookingHistoryParams = {
  member_id: number;
  status?: 'booked_list' | 'attendance' | 'absence' | 'noshow' | 'cancel';
  order_by: 'asc' | 'desc';
  user_ticket_id?: number;
};

export type BookingHistoryResponse = {
  id: number;
  status: string;
  lecture: Lecture;
  user_ticket: UserTicket;
  created_at: string;
  updated_at: string;
  waiting_order: number; // 그룹수업 대기 상태일 때 순번 (대기 상태 아니면 0)
};

export type Lecture = {
  id: number;
  studio_id: number;
  type: string;
  title: string;
  course_id: number;
  instructor_id: number;
  room_id: null;
  booking_closed_at: null;
  division_id: null;
  attendance_type: string;
  is_booking_only: boolean;
  start_on: string;
  end_on: string;
  min_trainee: number;
  max_trainee: number;
  current_trainee_count: number;
  total_current_trainee_count: number;
  comparison_min_trainee_of_total_current_count: number;
  waiting_trainee_limit: number;
  coupon_deduction: boolean;
  booking_start_at: null;
  booking_end_at: string;
  enter_start_at: string;
  enter_end_at: string;
  booking_cancel_start_at: null;
  booking_cancel_end_at: string;
  booking_auto_shift_available_minutes_from_start: string;
  daily_change_booking_end_at: string;
  autoclose_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  deleted_for: null;
  deleted_by: null;
  staff: Staff;
  room: null;
};

export type Staff = {
  id: number;
  studio_id: number;
  user_grade_id: null;
  name: string;
  account_type: string;
  account_id: null;
  mobile: null;
  vaccination_yn: string;
  created_at: string;
  updated_at: string;
  registered_at: string;
  deleted_at: null;
  staff_avatar: StaffAvatar;
};

export type StaffAvatar = {
  id: number;
  owner_type: string;
  owner_id: number;
  is_representative: number;
  image: Image;
  created_at: string;
  updated_at: string;
  deleted_at: null;
};

export type Image = {
  path: string;
  name: string;
  extension: string;
};

export type UserTicket = {
  id: number;
  studio_id: number;
  user_id: number;
  ticket_id: number;
  order_item_id: number;
  max_coupon: number;
  remaining_coupon: number;
  usable_coupon: number;
  max_cancel: number;
  remaining_cancel: number;
  max_modify: number;
  remaining_modify: number;
  booking_limit_per_week: number;
  booking_limit_per_month: number;
  is_active_holding_function: boolean;
  availability_start_at: string;
  expire_at: string;
  active: boolean;
  inactive_reason: null;
  created_at: string;
  is_shared: boolean;
  un_check: boolean;
  is_show_cancel_count: boolean;
  staff_updated_at: string;
  updated_at: string;
  payment_updated_at: string;
  deleted_at: null;
  booking_count_per_week: number;
  staff_id: null;
  status: string;
  ticket: Ticket;
};

export type Ticket = {
  id: number;
  studio_id: number;
  title: string;
  description: null;
  type: string;
  favorite: number;
  favorite_stamp: null;
  available_class_type: string;
  min_trainee: number;
  max_trainee: number;
  max_coupon: number;
  max_cancel: number;
  max_modify: number;
  booking_limit_per_week: number;
  booking_limit_per_month: number;
  daily_booking_change_limit: number;
  class_period: number;
  is_active_holding_function: number;
  is_show_cancel_count: number;
  is_ignore_new_payment: boolean;
  colors: string;
  availability_start_at: null;
  expire_at: null;
  available_period: number;
  booking_start_time: null;
  booking_end_time: null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  use_weekly_auto_coupon_balance: boolean;
};

export type FilterTicketList = {
  id: number;
  title: string;
  status: Status;
};

export enum Status {
  Expired = 'expired',
  Using = 'using',
}
