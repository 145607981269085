import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Form from 'components/Form';
import useGetMinHeight from 'hooks/useGetMinHeight';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { UseFieldArrayPrepend, UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

import SubmitButtonGroup from '../StaffCreateForm/components/SubmitButtonGroup';
import { ProfileUpdateFormType, StaffCreateFormType } from '../types';
import Careers from './Careers';
import SelfIntroduction from './SelfIntroduction';

type Props = {
  onSubmit: () => void;
  careerFields: StaffCreateFormType['careers'];
  prepend: UseFieldArrayPrepend<StaffCreateFormType, 'careers'> | UseFieldArrayPrepend<ProfileUpdateFormType, 'careers'>;
  remove: UseFieldArrayRemove;
  isUpdateForm?: boolean;
  disabled?: boolean;
  nextStep?: () => void;
};

const StaffProfileForm = ({ onSubmit, careerFields, prepend, remove, isUpdateForm, nextStep }: Props) => {
  const minHeight = useGetMinHeight();
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);

  const {
    control,
    formState: { isDirty, isSubmitSuccessful, defaultValues },
  } = useFormContext();

  const values = useWatch({ control });
  const currentValues = useMemo(() => {
    const { careers } = values;
    if (careers.length === 1 && careers[0].career === undefined) {
      return { ...values, careers: [] };
    }
    return values;
  }, [values]);

  return (
    <>
      <Container onSubmit={onSubmit} minHeight={minHeight} isUpdateForm={isUpdateForm}>
        <div>
          <SelfIntroduction />
          <Careers careerFields={careerFields} prepend={prepend} remove={remove} />
        </div>

        {isUpdateForm ? (
          <ButtonWrapper isKeyboardShow={isKeyboardShow}>
            <Button
              type="submit"
              variant="contained"
              color="point"
              size="large56"
              fullWidth
              disabled={isEqual(defaultValues, currentValues)}>
              수정 완료
            </Button>
          </ButtonWrapper>
        ) : (
          <SubmitButtonGroup buttonText="다음" onClick={nextStep} />
        )}
      </Container>
      <DialogBeforeLeave isBlocked={isDirty && !isSubmitSuccessful} />
    </>
  );
};

export default StaffProfileForm;

const Container = styled(Form)<{ minHeight: number; isUpdateForm?: boolean }>`
  ${theme.flex('column', 'center', 'space-between')};
  padding: 16px 20px 10px;
  min-height: ${({ minHeight, isUpdateForm }) => `${isUpdateForm ? minHeight : minHeight - 40 - 82}px`};

  > div {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div<{ isKeyboardShow: boolean }>`
  ${({ isKeyboardShow }) =>
    !isKeyboardShow &&
    `
    position: fixed;
    bottom: 0;
    padding: 16px 20px 10px;
  `}
  background-color: ${theme.color.white};
`;
