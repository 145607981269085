import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import usePopup from 'hooks/usePopup';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { TICKET_MEMO_TEXT } from 'pages/TicketDetail/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import MemoForm from 'sharedComponents/MemoForm';
import MemoList, { MemoListContainer } from 'sharedComponents/MemoForm/MemoList';
import Sorting from 'sharedComponents/MemoForm/Sorting';
import { MemoFormType, SortFormType } from 'sharedComponents/MemoForm/types';

type Props = {
  productId: number;
};

const TicketMemo = ({ productId }: Props) => {
  const { setPopup } = usePopup();
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();
  const canCreateMemo = hasPermission(MEMBER_PERMISSION.memberTicketEdit);

  const formValues: MemoFormType = {
    ref_type: 'user_tickets',
    ref_id: productId,
    memo: '',
    imgUrls: [],
    files: [],
  };

  const methods = useForm<MemoFormType & SortFormType>({
    defaultValues: {
      ...formValues,
      sort: 'desc',
    },
  });

  const clickCreateMemo = () => {
    checkPermission(MEMBER_PERMISSION.memberTicketEdit.id, {
      onSuccess: () => {
        setPopup(<MemoForm formValues={formValues} />);
      },
      onError: () => {
        navigate(-1);
      },
    });
  };

  return (
    <MainLayout
      header={{
        title: TICKET_MEMO_TEXT.title,
        rightButton: (
          <>
            {canCreateMemo ? (
              <IconButton onClick={clickCreateMemo}>
                <Icon name="headerPlus" fillColor={theme.color.gray1} />
              </IconButton>
            ) : undefined}
          </>
        ),
      }}>
      <FormProvider {...methods}>
        <MemoListContainer>
          <Sorting />
          <ApiBoundary>
            <MemoList
              updatePermission={MEMBER_PERMISSION.memberTicketEdit}
              deletePermission={MEMBER_PERMISSION.memberTicketEdit}
            />
          </ApiBoundary>
        </MemoListContainer>
      </FormProvider>
    </MainLayout>
  );
};

export default TicketMemo;
