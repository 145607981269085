import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import useGetBoardNoticeDetail from 'hooks/service/queries/useGetBoardNoticeDetail';
import usePermission from 'hooks/usePermission';
import { useErrorBoundary } from 'react-error-boundary';
import { Navigate } from 'react-router-dom';
import TextWithURL from 'sharedComponents/Board/TextWithURL';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { NOTICE_TEXT } from '../../constants';
import { periodText } from '../../utils';
import BottomButtons from './BottomButtons';
import FoldableSettings from './FoldableSettings';

type Props = {
  id: number;
  permissionDenied?: boolean;
};

const NoticeDetail = ({ id, permissionDenied }: Props) => {
  const { hasPermission } = usePermission();
  const canViewNotice = hasPermission(PERMISSION.board.notice.view);
  const canCallApi = !permissionDenied || canViewNotice;

  const { data: notice, isError, error } = useGetBoardNoticeDetail(id, canCallApi);
  const { showBoundary } = useErrorBoundary();

  if (!canCallApi) {
    return <Navigate to="/more-details" replace />;
  }
  if (isError) {
    showBoundary(error);
  }
  if (!notice) {
    return null;
  }
  const noticeSetting = filters.separator([
    notice.popup_on ? NOTICE_TEXT.popup : '',
    notice.is_top_fixed ? NOTICE_TEXT.topFixed : '',
  ]);
  const noticeTarget = filters.separator([
    ...(notice.target_member === 'both' ? [NOTICE_TEXT.targetActive, NOTICE_TEXT.targetExpired] : ['']),
    notice.target_member === 'active' ? NOTICE_TEXT.targetActive : '',
    notice.target_member === 'expired' ? NOTICE_TEXT.targetExpired : '',
  ]);

  return (
    <>
      <Container>
        <Typography size={17} weight={700} className="title">
          {notice.title}
        </Typography>
        <ArrangeTypographyWrapper className="details">
          <Typography textColor="gray2" opacity={0.88} span>
            {notice.staff.name}
          </Typography>
          <Typography textColor="gray2" opacity={0.88} span>
            {(notice.staff.roles?.length && notice.staff.roles[0]?.display_name) ?? ''}
          </Typography>
          <Typography textColor="gray2" opacity={0.88} span>
            {filters.dateTimeWithWeekday(notice.updated_at)}
          </Typography>
        </ArrangeTypographyWrapper>
        <FoldableSettings setting={noticeSetting || '-'} period={periodText(notice)} target={noticeTarget} />
        <Contents size={15} weight={500}>
          <TextWithURL text={notice.contents} />
        </Contents>
        {notice.attachments?.map(attachment => (
          <Image key={attachment.id} src={getImageUrl(attachment.path, '0x0')} alt={notice?.title} />
        ))}
      </Container>
      <ApiBoundary>
        <BottomButtons notice={notice} />
      </ApiBoundary>
    </>
  );
};

export default NoticeDetail;

const Container = styled.div`
  padding: 16px 20px 80px;

  .title {
    margin-bottom: 8px;
  }
  .details > .typography:nth-of-type(2) {
    padding-left: 6px;
    :before {
      display: none;
    }
  }
`;

const Contents = styled(Typography)`
  margin: 40px 0;
  white-space: pre-wrap;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;
