import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';

import { CheckItemType } from '../../types';

type Props = {
  isCheckedParent: boolean; // 상위 항목 체크 여부
  details: CheckItemType['details'];
  isLastIndex: boolean;
  ids: number[];
  setIds: (checked: boolean, checkIds: number[]) => void;
};

const DetailCheckList = ({ isCheckedParent, details, isLastIndex, ids, setIds }: Props) => {
  return (
    <>
      {isLastIndex && <Divider thin />}
      <Container>
        {details.map(({ id, label }) => {
          const isChecked = ids.includes(id);
          return (
            <StyledCheckBox
              key={id.toString()}
              id={id.toString()}
              label={label}
              gap={10}
              variant="simple"
              checkBold
              onChange={({ target: { id: checkId, checked } }) => {
                setIds(checked, [Number(checkId)]);
              }}
              disabled={!isCheckedParent}
              checked={isChecked}
            />
          );
        })}
      </Container>
      {!isLastIndex && <Divider thin />}
    </>
  );
};

export default DetailCheckList;

const Container = styled.section`
  ${theme.flex('column', 'flex-start', 'center', 12)};
  padding: 0 16px 0 32px;

  .checkbox {
    padding: 5px 0;
  }
`;

const StyledCheckBox = styled(CheckBox)<{ checked: boolean }>`
  .input-span-style {
    display: none;
  }

  .typography {
    color: ${({ checked }) => theme.color[checked ? 'gray2' : 'gray3']};
    font-weight: 500;
  }
`;
