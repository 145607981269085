import styled from '@emotion/styled';
import Loading from 'components/Loading';
import { ReactNode, TouchEvent, useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  children: ReactNode;
  /** 당겨지는 최대 거리. default: 60 */
  maxPullDistance?: number;
  /** 당길 때 사용자가 느끼는 저항계수 */
  pullResistance?: number;
  /** refresh가 끝나면 컨테이너를 초기화하는 용도. useQuery의 isRefetching 옵션 */
  isRefetching: boolean;
  onRefresh: () => void;
  className?: string;
};

const PullToRefresh = ({ children, maxPullDistance = 60, pullResistance = 0.73, isRefetching, onRefresh, className }: Props) => {
  const spinnerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  /** 터치 시작 위치 */
  const [startY, setStartY] = useState(0);
  /** refresh 가능한 상태인지 여부 */
  const [isRefresh, setIsRefresh] = useState(false);
  const [isClickStart, setIsClickStart] = useState(false);

  const reset = useCallback(() => {
    if (!spinnerRef.current) return;
    spinnerRef.current.style.visibility = 'hidden';
    spinnerRef.current.style.height = '0';
    spinnerRef.current.style.willChange = 'unset';
    setIsRefresh(false);
  }, []);

  useEffect(() => {
    /** refetch 끝난 후에 요소 초기화  */
    if (!isRefetching) {
      reset();
    }
  }, [isRefetching, reset]);

  const onTouchStart = (e: TouchEvent) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!spinnerRef.current) return;
    setStartY(e.touches[0].clientY);
    spinnerRef.current.style.willChange = 'height';
  };

  const onTouchMove = (e: TouchEvent) => {
    const isDrawerEvent = !!(e.target as HTMLElement).closest('.drawer');
    if (isDrawerEvent || !spinnerRef.current) return;
    spinnerRef.current.style.visibility = 'visible';
    const moveY = e.touches[0].clientY;
    /** 당길 때 사용자가 느끼는 저항계수를 삽입해 계산 */
    const pullDistance = Math.min(Math.pow(moveY - startY, pullResistance), maxPullDistance);
    if (pullDistance) {
      setIsClickStart(true);
    }

    /** 최대 수치보다 많이 당기면 최대값으로 크기 고정 */
    if (pullDistance >= maxPullDistance) {
      spinnerRef.current.style.height = `${maxPullDistance}px`;
      setIsRefresh(true);
    } else {
      spinnerRef.current.style.height = `${pullDistance}px`;
      setIsRefresh(false);
    }
  };

  const onTouchEnd = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (isRefresh) {
      onRefresh();
    } else {
      reset();
    }

    timeoutRef.current = setTimeout(() => {
      setIsClickStart(false);
    }, 2000);
  };

  return (
    <Container className={className ? `pull-to-refresh ${className}` : 'pull-to-refresh'}>
      <div ref={spinnerRef} className="spinner">
        {isClickStart && <Loading type="refresh" key={startY} />}
      </div>
      <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        {children}
      </div>
    </Container>
  );
};

export default PullToRefresh;

const Container = styled.div`
  .spinner {
    visibility: hidden;
    overflow: hidden;
    position: relative;
    transition: all 0.1s;

    > div {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
