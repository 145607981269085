import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useGetStaffMe from 'hooks/service/queries/useGetStaffMe';
import usePermission from 'hooks/usePermission';
import { BOOKING_SELECT_STAFF_PERMISSION } from 'pages/Booking/constants';
import { BookingCommonFormType, BookingType, PageModeType } from 'pages/Booking/types';
import { ComponentProps, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FallbackStaffField from './FallbackStaffField';
import StaffLinkButton from './StaffLinkButton';
import Staffs from './Staffs';

type Props = {
  bookingType: BookingType;
  pageMode: PageModeType;
  lectureStaff?: LectureDetailResponse['staff'];
};

/**
 * 본인 수업, 다른 스태프 수업 등록 및 수정 가능한 지 권한 확인하여,
 * 강사 선택 가능 여부 판단
 */
const CheckPermissionSelectStaff = ({ bookingType, pageMode, lectureStaff }: Props) => {
  const { setValue } = useFormContext<BookingCommonFormType>();
  const { data } = useGetStaffMe();
  const { hasPermission } = usePermission();
  /** 본인 수업만 생성/수정 가능 */
  const canUpdateOnlyMine = hasPermission(BOOKING_SELECT_STAFF_PERMISSION.mine[pageMode][bookingType]);
  /** 다른 스태프 수업만 생성/수정 가능 */
  const canUpdateOnlyOthers = hasPermission(BOOKING_SELECT_STAFF_PERMISSION.others[pageMode][bookingType]);

  const canSelectStaff = useMemo(() => {
    if (canUpdateOnlyMine && canUpdateOnlyOthers) {
      return true;
    } else if (canUpdateOnlyOthers) {
      return true;
    } else {
      return false;
    }
  }, [canUpdateOnlyMine, canUpdateOnlyOthers]);

  const currentStaff: ComponentProps<typeof Staffs>['currentStaff'] = useMemo(() => {
    if (lectureStaff) {
      const { id, name, image, representative_color } = lectureStaff;
      return {
        id,
        name,
        image,
        color: representative_color,
      };
    } else {
      const { id, name, profile, avatar } = data;
      return {
        id,
        name,
        image: avatar ? avatar.image : null,
        color: profile.representative_color,
      };
    }
  }, [data, lectureStaff]);

  useEffect(() => {
    if (!canSelectStaff) {
      const staff = {
        id: currentStaff.id,
        avatar: currentStaff.image,
        name: currentStaff.name,
        color: currentStaff.color,
      };
      setValue('selectedStaff', staff);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSelectStaff]);

  /**
   * 폼 진입 시, 현재 로그인 한 강사(수정 시, 수업 담당 강사)를
   * 필드에 default로 보여줘야 하는데,
   * 현재 강사가 강사 리스트에서 1페이지에 없는 경우 싱크가 안 맞아 디폴트로 보여줄 수 없어
   * currentStaff 를 넘김.
   */
  return (
    <ApiBoundary fallback={<FallbackStaffField />}>
      {canSelectStaff ? (
        <Staffs currentStaff={currentStaff} canUpdateOnlyMine={canUpdateOnlyMine} />
      ) : (
        <StaffLinkButton
          showArrow={false}
          staffInfo={{
            name: currentStaff.name,
            color: currentStaff.color,
            imgUrl: currentStaff.image,
          }}
        />
      )}
    </ApiBoundary>
  );
};

export default CheckPermissionSelectStaff;
