import { MbscCalendarEventData } from '@mobiscroll/react';

import ListTypeAgendaCard from './ListTypeAgendaCard';

type Props = {
  events: MbscCalendarEventData[];
};

const ListTypeAgendaList = ({ events }: Props) => {
  return events.map(event => <ListTypeAgendaCard key={event.id} event={event} />);
};

export default ListTypeAgendaList;
