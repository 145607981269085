import { theme } from 'assets/styles';
import dayjs from 'dayjs';
import { AllDayEtcScheduleResponse } from 'hooks/service/queries/useGetAllScheduleAllDayEtcSchedule';
import { ScheduleCounselResponse } from 'hooks/service/queries/useGetAllScheduleCounsel';
import { EtcScheduleResponse } from 'hooks/service/queries/useGetAllScheduleEtcSchedule';
import { ScheduleLectureResponse } from 'hooks/service/queries/useGetAllScheduleLecture';
import getColor, { Color } from 'utils/getColor';

import { EVENT_CARD_TYPE } from '../constants';

/** EventCalendar data 속성에 start, end 프로퍼티는 꼭 있어야 작동함(mobiscroll) */
export const lectureDataset = (events: ScheduleLectureResponse) => {
  return events.map(
    ({ type, id, title, start_on, end_on, current_trainee_count, max_trainee, staff, booking_closed_at, room }) => {
      const matchColor = (getColor(staff.profile?.representative_color) || 'red0') as Color;
      return {
        type,
        id,
        start: start_on ? dayjs(start_on).toDate() : undefined,
        end: end_on ? dayjs(end_on).toDate() : undefined,
        title,
        currentTraineeCount: current_trainee_count,
        maxTrainee: max_trainee,
        color: theme.color[matchColor],
        staffName: staff.name,
        staffId: staff.id,
        isBookingClosed: !!booking_closed_at, // 예약 마감 여부. (마감시 배경 채우기 설정 용도)
        room,
      };
    },
  );
};

export const counselDataset = (events: ScheduleCounselResponse) => {
  return events.map(({ id, start_on, end_on, name, staff }) => {
    const matchColor = (getColor(staff.profile?.representative_color) || 'red0') as Color;
    return {
      type: EVENT_CARD_TYPE.counsel,
      id,
      start: start_on ? dayjs(start_on).toDate() : undefined,
      end: end_on ? dayjs(end_on).toDate() : undefined,
      title: name,
      color: theme.color[matchColor],
      staffName: staff.name,
      staffId: staff.id,
    };
  });
};

export const etcScheduleDataset = (events: EtcScheduleResponse) => {
  return events.map(({ id, title, start_on, end_on, color, is_private, register, staffs }) => {
    const matchColor = (getColor(color) || 'red0') as Color;
    return {
      type: EVENT_CARD_TYPE.etcSchedule,
      id,
      start: start_on ? dayjs(start_on).toDate() : undefined,
      end: end_on ? dayjs(end_on).toDate() : undefined,
      title,
      color: theme.color[matchColor],
      register,
      staffs: staffs.map(staff => {
        return { id: staff.id, name: staff.name };
      }),
      isPrivate: is_private,
      allDay: false,
    };
  });
};

export const allDayEtcScheduleDataset = (events: AllDayEtcScheduleResponse) => {
  const result = events.map(
    ({ id, color, title, is_private, register, staffs, start_on, end_on, is_repeat, is_bulk_all_day, created_at }) => {
      const matchColor = (getColor(color) || 'red0') as Color;
      return {
        type: EVENT_CARD_TYPE.allDayEtcSchedule,
        id,
        start: start_on ? dayjs(start_on).toDate() : undefined,
        end: end_on ? dayjs(end_on).toDate() : undefined,
        title,
        color: theme.color[matchColor],
        register,
        staffs: staffs.map(staff => {
          return { id: staff.id, name: staff.name };
        }),
        isPrivate: is_private,
        allDay: true,
        isRepeat: is_repeat,
        isBulkAllDay: is_bulk_all_day,
        createdAt: created_at,
      };
    },
  );
  return result.flat();
};
