import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import useGetStaffEventSchedules from 'hooks/service/queries/useGetStaffEventSchedules';
import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import { orderBy } from 'lodash';
import { StaffFieldFormType } from 'pages/MoreDetails/Staff/List/types';
import { Fragment, useDeferredValue, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { listHeaderTotalAtom } from 'recoil/staff';
import getEventTimeDatasetNew from 'utils/getEventTimeDatasetNew';

import EventScheduleCard from './EventScheduleCard';
import Pagination from './Pagination';

const LIMIT = 20;

const EventScheduleCardList = () => {
  const setListHeaderTotalAtom = useSetRecoilState(listHeaderTotalAtom);

  const targetStaffId = useParamsId();
  const { data: eventSchedules } = useGetStaffEventSchedules({ targetStaffId });
  const eventTimesDataset = useMemo(() => getEventTimeDatasetNew(eventSchedules), [eventSchedules]);

  const { control } = useFormContext<StaffFieldFormType>();
  const sort = useWatch({ control, name: 'sort' });

  const { getSearchParams } = useQueryString();
  const page = Number(getSearchParams('page')) || 1;

  useEffect(() => {
    setListHeaderTotalAtom(eventTimesDataset.length);
  }, [setListHeaderTotalAtom, eventTimesDataset.length]);

  const pageData = useMemo(() => {
    const sortData = orderBy(eventTimesDataset, ['date'], [sort]);
    if (sortData.length <= LIMIT) return sortData;

    const startIndex = page === 1 ? 0 : (page - 1) * LIMIT;
    const lastIndex = startIndex + LIMIT;
    return sortData.slice(startIndex, lastIndex);
  }, [page, sort, eventTimesDataset]);

  const deferredPageData = useDeferredValue(pageData);

  if (!eventTimesDataset.length) {
    return (
      <NoContentContainer>
        <Typography size={15} textColor="gray2" opacity={0.8}>
          등록된 일정이 없습니다.
        </Typography>
      </NoContentContainer>
    );
  }

  return (
    <>
      <ul>
        {deferredPageData.map((workTime, index, list) => {
          return (
            <Fragment key={workTime.id}>
              <EventScheduleCard workTime={workTime} dataset={eventTimesDataset} />
              {list.length - 1 !== index && <Divider thin />}
            </Fragment>
          );
        })}
      </ul>
      {eventTimesDataset.length > LIMIT && <Pagination max={Math.ceil(eventTimesDataset.length / LIMIT)} />}
    </>
  );
};

export default EventScheduleCardList;

const NoContentContainer = styled.div`
  ${theme.flex()};
  margin-top: 100px;
`;
