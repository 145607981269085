import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Dispatch, Fragment, SetStateAction } from 'react';

import { CheckListType } from '../../types';
import DetailCheckList from './DetailCheckList';
import MenusLabel from './MenusLabel';

type Props = {
  checkList: CheckListType;
  ids: number[];
  isOpenDetails: number[];
  setIds: (checked: boolean, checkIds: number[]) => void;
  setIsOpenDetails: Dispatch<SetStateAction<number[]>>;
  toggleDetail: (detailIndex: number) => void;
};

const SettingCheckList = ({ checkList, ids, isOpenDetails, setIds, setIsOpenDetails, toggleDetail }: Props) => {
  return (
    <Container>
      {checkList.map(({ id, title, label, description, details }, index, list) => {
        const isLastIndex = list.length - 1 === index;
        const isChecked = ids.includes(id);
        const isDetailList = !!details.length;
        const isOpenDetail = isOpenDetails.includes(index); // 상세 열림/닫힘 상태
        const detailIds = details.map(detail => detail.id);
        const hasUncheckedDetails = isChecked && details.length > 0 && detailIds.some(detailId => !ids.includes(detailId)); // 하위 항목 중 하나라도 체크되지 않았으면 true (상위 체크박스 아이콘 변경용)

        return (
          <Fragment key={label}>
            {title && (
              <Typography className="title" weight={500} textColor="gray2">
                {title}
              </Typography>
            )}

            <div className="checkbox-item-wrapper">
              <CheckBox
                id={id.toString()}
                label={<MenusLabel label={label} description={description} />}
                gap={16}
                checkBold
                onChange={({ target: { id: checkId, checked } }) => {
                  setIds(checked, [Number(checkId), ...detailIds]);
                  // 체크박스 체크 시 열림/닫힘 상태와 별도로 상세 정보는 열리게 유지
                  if (checked && isDetailList && !isOpenDetail) {
                    setIsOpenDetails(prev => [...prev, index]);
                  } else if (!checked && isDetailList) {
                    setIsOpenDetails(prev => prev.filter(i => i !== index));
                  }
                }}
                checked={isChecked}
                iconName={hasUncheckedDetails ? 'lineBold' : 'selectCheckBold'}
              />
              {isDetailList && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation(); // 체크박스 이벤트와 충돌 방지
                    toggleDetail(index); // 아이콘 버튼 클릭 시 열림/닫힘 상태 변경
                  }}>
                  <Icon name={isOpenDetail ? 'arrowTop' : 'arrowBottom'} fillColor={theme.color.gray3} />
                </IconButton>
              )}
            </div>

            {!isLastIndex && <Divider thin />}

            {isOpenDetail && isDetailList && (
              <DetailCheckList
                isCheckedParent={isChecked}
                details={details}
                isLastIndex={isLastIndex}
                ids={ids}
                setIds={setIds}
              />
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default SettingCheckList;

const Container = styled.ul`
  margin-bottom: 120px;
  padding: 12px 0 16px;
  border-radius: 12px;
  background-color: ${theme.color.gray7};

  .title {
    padding: 12px 16px;
  }

  .checkbox-item-wrapper {
    ${theme.flex('row', 'center', 'space-between', 16)};
    padding: 0 16px;

    .input-select-base-input-wrapper {
      flex: 0 0 auto;
    }
  }

  .checkbox {
    padding: 8px 0;

    .input-span-style {
      background-color: ${theme.color.white};
    }
  }

  hr {
    margin: 12px 0;
    width: 100%;
    background-color: ${theme.color.gray5};
    opacity: 0.8;
  }
`;
