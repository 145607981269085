import { css } from '@emotion/react';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';
import { HolidayTargetResponse } from 'hooks/service/queries/useGetHolidayTarget';

import { CalendarViewType } from './types';

type Props = {
  viewType?: CalendarViewType;
  isDayWeekAllDay?: boolean;
  allDaysLength: number[];
  isAtTop?: boolean;
  holidayTargets?: HolidayTargetResponse;
};

const defaultStyle = ({ viewType, isDayWeekAllDay, allDaysLength, isAtTop }: Props) => css`
  background-color: ${theme.color.white};
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;

  /** .mbsc-schedule-grid-scroll 클래스와 touch-action이 중복 적용되어 에러가 발생. 이를 해결하기 위해 넣음 */
  touch-action: none;

  /** 타임라인 인디케이터 요일 포인터 미노출 */
  .mbsc-schedule-time-indicator {
    display: none;
  }

  .mbsc-schedule-grid-scroll {
    ${viewType === 'day' && 'padding-right: 20px'};
  }

  .mbsc-schedule-event-custom {
    &:not(.mbsc-schedule-event-all-day) {
      padding-bottom: 0.6px !important;
    }
  }

  /** 기타일정 종일 데이터가 없으면 해당 영역 미노출 */
  .header-wrapper {
    ${viewType === 'day' && !isDayWeekAllDay && 'margin-bottom: 7px'};
  }
  .mbsc-schedule-all-day-cont {
    ${!isDayWeekAllDay && 'display: none'};
  }

  .mbsc-schedule-all-day-item {
    padding: 0;
  }

  .mbsc-ios {
    &.mbsc-eventcalendar,
    .mbsc-calendar-wrapper {
      border-top-right-radius: 24px;
      border-top-left-radius: 24px;
    }

    /** 컨테이너 */
    &.mbsc-eventcalendar {
      overflow: visible;
    }

    .mbsc-calendar-wrapper {
      position: sticky;
      top: 0;
      z-index: ${Z_INDEX.stickyTab};
    }

    /** 컨테이너 헤더 */
    .mbsc-calendar-header {
      border-top-right-radius: 24px;
      border-top-left-radius: 24px;
      z-index: 3;
      background: ${theme.color.white};
      transition: border-radius 0.2s ease;
      ${isAtTop &&
      `
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    `}

      .mbsc-calendar-controls {
        padding: 0;
      }

      .header-wrapper {
        padding-bottom: ${viewType === 'day' ? '14px' : '8px'};
      }
    }

    /** 캘린더 헤더 */
    .mbsc-schedule-header {
      display: none;
      border-bottom: none;
      background-color: ${theme.color.white};

      /** 요일/날짜 그룹 */
      .mbsc-schedule-header-item {
        ${theme.flex('column', 'center', 'center', 6)};
        width: 26px;
        line-height: inherit;

        .mbsc-schedule-header-dayname {
          line-height: 12px;
          font-size: 1.2rem;
          color: ${theme.color.gray2};
          opacity: 0.88;
        }

        .mbsc-schedule-header-day {
          ${theme.flex()};
          font-size: 1.4rem;
          color: ${theme.color.gray2};

          &.mbsc-selected {
            width: 24px;
            height: 24px;
            font-weight: 700;
            color: ${theme.color.primary} !important;
            background-color: rgba(108, 133, 251, 0.2);
          }
        }
      }
    }

    .mbsc-schedule-wrapper {
      overflow: visible;
    }

    .mbsc-schedule-all-day-cont {
      position: sticky;
      top: 60px;
      background-color: ${theme.color.white};
      z-index: ${Z_INDEX.stickyTab};
    }

    /** 종일 일정 */
    .mbsc-schedule-all-day {
      ${theme.flex('row', 'flex-start', 'center')};

      .mbsc-schedule-all-day-group-wrapper {
        overflow-y: scroll;

        /** 종일 일정 개수에 따라 테이블 높이 조정 */
        .mbsc-schedule-resource-group {
          ${viewType === 'day' && `height: ${allDaysLength[0] > 2 ? 78 : allDaysLength[0] === 2 ? 63 : 35}px !important`};
        }

        /** 종일 일정이 3개 이상일 때는 2번째 요소까지만 노출  */
        .mbsc-schedule-event-all-day {
          margin-bottom: 4px;
          padding: 0 20px 0 0;
          height: 24px;

          > div {
            ${theme.flex('row', 'center', 'flex-start')};
            padding: 0 8px;
          }
        }
      }
    }

    .mbsc-schedule-event {
      &:not(.mbsc-schedule-event-all-day) {
        min-height: inherit;
      }

      > div {
        ${viewType === 'day' && 'margin-left: 1px'};
      }
    }

    .mbsc-schedule-event-start {
      padding: 0;
    }

    .mbsc-flex-col.mbsc-flex-1-1.mbsc-schedule-grid-scroll.mbsc-ios {
      ${viewType === 'day' && 'padding-top: 25px !important'};
    }

    .mbsc-flex-col.mbsc-flex-1-0.mbsc-schedule-time-wrapper.mbsc-ios.mbsc-ltr,
    .mbsc-flex-1-1.mbsc-schedule-time.mbsc-ios.mbsc-ltr {
      max-height: 50px !important;
    }

    .mbsc-schedule-time-wrapper:not(.mbsc-schedule-time-wrapper-end) {
      &.mbsc-flex-none {
        ${viewType === 'day' && 'top: 12.5px'};
      }
    }

    .mbsc-schedule-time-wrapper-end {
      height: 50px !important;
    }

    .mbsc-schedule-grid-wrapper {
      margin-top: ${isDayWeekAllDay ? '19px' : 0};
    }

    /** 타임라인 시간 영역 */
    .mbsc-schedule-wrapper {
      height: 100%;

      .mbsc-schedule-time {
        ${theme.flex()};
        top: 0px;

        &.mbsc-schedule-time-end {
          display: none;
        }
      }

      .mbsc-schedule-column {
        padding: 0 !important;
      }

      .mbsc-schedule-time-cont-inner {
        padding: 0;
      }

      .mbsc-schedule-all-day-text,
      .mbsc-schedule-time {
        font-size: 1.2rem;
        text-align: center;
        color: ${theme.color.gray2};
      }

      .mbsc-schedule-time,
      .mbsc-schedule-time-col {
        width: 60px;
      }

      .mbsc-schedule-time-col {
        .mbsc-schedule-all-day-text {
          font-weight: 500;
        }
      }

      .mbsc-schedule-all-day-wrapper {
        max-height: max-content;
        overflow-y: visible;
      }

      /** 타임라인 격자 스타일 */
      .mbsc-schedule-all-day-wrapper,
      .mbsc-schedule-item {
        border: 0;
        border-bottom: 1px solid ${theme.color.gray6} !important;
      }

      .mbsc-schedule-item.mbsc-flex-1-0.mbsc-ios {
        &:nth-of-type(2) {
          border-top: 1px solid ${theme.color.gray6} !important;
        }
      }
    }

    /** 좌우 스와이프를 위해서 삽입 */
    .mbsc-flex-col.mbsc-schedule-grid-scroll {
      touch-action: pan-y;
      overflow-y: hidden;
    }

    .mbsc-schedule-events.mbsc-ltr {
      right: 0;
    }
  }
`;

const monthStyle = css`
  .mbsc-ios {
    .mbsc-calendar-cell-text {
      width: 20px;
      height: 20px;
      border: 0;
      line-height: 1.8;
    }

    /** 월간 형태일 때, 다른 날짜 선택 시 스타일 적용 */
    &.mbsc-selected .mbsc-calendar-cell-text {
      font-weight: 700;
      color: ${theme.color.primary} !important;
      background-color: rgba(108, 133, 251, 0.2);
    }

    .mbsc-calendar-wrapper {
      border: 0;

      .mbsc-calendar-body-inner {
        height: calc(100vh - 171px); // 각 헤더 + 하단 내비게이션 높이를 계산

        /** 캘린더 테이블 컨테이너 */
        .mbsc-calendar-table {
          .mbsc-calendar-week-days {
            margin-top: 7px;
            padding: 0 10px;

            /** 요일 텍스트 */
            .mbsc-calendar-week-day {
              font-size: 1.2rem;
              color: ${theme.color.gray2};
              opacity: 0.88;
            }

            .mbsc-calendar-week-day:first-of-type {
              color: #f4675c !important;
            }

            .mbsc-calendar-week-day:last-of-type {
              color: #0d88d9 !important;
            }
          }

          /** 각 cell의 날짜(숫자) 텍스트 */
          .mbsc-calendar-week-day,
          .mbsc-calendar-cell-text {
            font-size: 1.2rem;
            color: ${theme.color.gray2};

            &.mbsc-calendar-today {
              font-weight: 700;
              border-color: ${theme.color.white};
              color: ${theme.color.primary} !important;
            }
          }

          .mbsc-calendar-row {
            padding: 0 10px;

            &:nth-of-type(2) {
              .mbsc-calendar-cell {
                border: 0;
              }
            }

            .mbsc-calendar-cell {
              border-color: ${theme.color.gray6};
            }

            .mbsc-calendar-cell-inner {
              /** 화면이 커지면 노출되는 불필요한 월간 텍스트 */
              .mbsc-calendar-month-name {
                display: none;
              }

              .mbsc-calendar-text.mbsc-calendar-custom-label {
                height: 14px;
              }

              /** 더보기 항목 active 색 고정 */
              .mbsc-calendar-text-more {
                &::before {
                  background-color: white;
                }

                .mbsc-calendar-label-text {
                  /** 보이지 안게 처리하고, 커스텀 before 표시 */
                  color: #00000000;
                  font-size: 0;

                  &::before {
                    content: '...';
                    font-size: 1.2rem;
                    font-weight: bold;
                    letter-spacing: 2px;
                    line-height: 1;
                    color: ${theme.color.gray3};
                  }
                }
              }
            }

            .mbsc-calendar-day:after {
              border: 0;
            }
          }
        }
      }
    }

    .mbsc-calendar-cell {
      border-top-width: 1px !important;
    }
  }
`;

const weekStyle = ({ allDaysLength, isDayWeekAllDay }: Props) => css`
  .mbsc-ios {
    /** 헤더 sticky를 위한 속성 */
    .mbsc-schedule-all-day-cont {
      top: 113px;
    }

    .mbsc-schedule-header {
      position: sticky;
      top: 54px;
      display: inherit;
      padding-top: 6px;
      padding-bottom: 9px;
      z-index: ${Z_INDEX.stickyTab + 1}; // 종일 헤더보다 앞에 나오게 하기 위한 용도
      border-bottom: ${!isDayWeekAllDay && `1px solid ${theme.color.gray6}`};

      .mbsc-schedule-header-item {
        padding: 0 9px;
        height: 44px;
      }
    }

    .mbsc-schedule-all-day-group-wrapper {
      height: ${allDaysLength.filter(length => length > 2).length
        ? '58px'
        : allDaysLength.filter(length => length === 2).length
          ? '53px'
          : '29px'} !important;
      border-left: 1px solid ${theme.color.gray6};

      .mbsc-schedule-event-all-day {
        margin: 0 !important;
        margin-bottom: 4px !important;
        padding: 0 !important;
        height: 20px !important;
      }
    }

    /** 요일 헤더 border 제거 */
    .mbsc-schedule-all-day-item {
      padding: 0;

      &:nth-of-type(n + 2) {
        border-left: 1px solid ${theme.color.gray6} !important;
      }

      &::after {
        display: none;
      }
    }

    /** 타임라인 세로 border 제거 */
    .mbsc-schedule-col-width {
      border: 0;
      border-color: ${theme.color.gray6};
    }

    .mbsc-schedule-events {
      position: static;

      .mbsc-schedule-event {
        padding: 0;
      }
    }

    .mbsc-schedule-time {
      top: 0;
      height: 56px !important;
    }

    .mbsc-schedule-time,
    .mbsc-schedule-column,
    .mbsc-schedule-time-cont-inner {
      padding: 0;
    }

    .mbsc-schedule-column {
      border-left: 1px solid ${theme.color.gray6} !important;
    }

    .mbsc-schedule-column-inner {
      margin-top: 25px;
    }

    .mbsc-flex-1-1.mbsc-schedule-time.mbsc-ios.mbsc-ltr {
      min-height: 50px !important;
    }

    .mbsc-schedule-time-end {
      display: none;
    }

    .mbsc-schedule-wrapper {
      padding-left: 0;
    }

    .mbsc-schedule-time,
    .mbsc-schedule-time-col {
      margin-top: 0 !important;
      width: 60px;
    }

    .mbsc-flex-col.mbsc-flex-1-0.mbsc-schedule-time-wrapper.mbsc-ios.mbsc-ltr {
      max-height: 50px !important;
    }

    .mbsc-schedule-grid-wrapper {
      margin: 0;
    }

    .mbsc-schedule-all-day-wrapper {
      padding-bottom: 0 !important;
    }

    .mbsc-schedule-all-day-text {
      padding: 3px 0;
    }

    .mbsc-schedule-fake-scroll-y {
      display: none;
    }
  }
`;

const listStyle = css`
  /**
    * 디바이스 화면 사이즈가 커지면 노출되는 mobiscroll 요소 삭제,
    * 아젠다 상단 날짜 항목 삭제
   */
  .mbsc-calendar-month-name,
  .mbsc-schedule-date-header {
    display: none;
  }

  .mbsc-calendar-wrapper {
    border-bottom: 2px solid ${theme.color.gray6} !important;
  }

  /** 하단 agenda */
  .mbsc-event-list {
    margin-bottom: 60px;
    padding-bottom: 60px;
    z-index: 1000;
  }

  .mbsc-calendar-table {
    gap: 4px;
    background-color: ${theme.color.white};
    padding: 8px 15px 10px;

    * {
      margin: 0;
      padding: 0;
    }
  }

  .mbsc-calendar-row,
  .mbsc-calendar-week-days {
    gap: 12px;

    .mbsc-calendar-week-day {
      color: ${theme.color.gray2} !important;
      opacity: 0.88;
    }

    .mbsc-calendar-week-day:first-of-type {
      color: #f4675c !important;
    }

    .mbsc-calendar-week-day:last-of-type {
      color: #0d88d9 !important;
    }
  }

  /** 캘린더 상단 요일 텍스트 */
  .mbsc-calendar-week-days {
    height: 18px;

    > .mbsc-calendar-week-day {
      ${theme.flex()};
      font-size: 1.3rem;
      line-height: 16px;
      color: ${theme.color.gray2};
    }
  }

  .mbsc-calendar-cell {
    height: 35px;
    border: 0;

    &.mbsc-hover {
      .mbsc-calendar-cell-text {
        background-color: transparent;
      }
    }

    .mbsc-calendar-cell-inner,
    .mbsc-calendar-cell-text {
      border: 0;
    }

    .mbsc-calendar-cell-inner {
      position: relative;
      ${theme.flex()};
    }

    .mbsc-calendar-cell-text {
      ${theme.flex()};
      margin-bottom: 3px;

      /** 캘린더 숫자 텍스트 */
      &.mbsc-calendar-day-text {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 22px;
      }

      /** 오늘 날짜 숫자 텍스트 */
      &.mbsc-calendar-today {
        font-weight: 700;
        color: ${theme.color.primary} !important;
      }
    }

    .mbsc-calendar-cell-text.mbsc-calendar-day-text {
      width: 25px !important;
      height: 25px !important;
    }

    /** 현재 달이 아닌 앞,뒤 달의 숫자 텍스트 */
    &.mbsc-calendar-day-outer .mbsc-calendar-cell-text {
      color: ${theme.color.gray3} !important;
      opacity: 0.8;
    }

    /** 선택되어 있는 숫자 텍스트 */
    &.mbsc-selected {
      .mbsc-calendar-cell-text.mbsc-calendar-day-text {
        font-weight: 700;
        color: ${theme.color.primary} !important;
        background-color: rgba(108, 133, 251, 0.2);
        border: 0;
      }
    }

    .mbsc-calendar-marks {
      height: 4px;

      .mbsc-calendar-mark {
        width: 4px;
        height: 4px;
        background-color: rgba(108, 133, 251, 0.72);
      }
    }

    .mbsc-calendar-cell-inner {
      div:last-of-type {
        bottom: 0;
      }
    }
  }
`;

const calendarStyles = {
  default: defaultStyle,
  week: weekStyle,
  month: monthStyle,
  list: listStyle,
};

export default calendarStyles;
