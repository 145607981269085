import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import OutlinedTextField from 'components/TextField/components/OutlinedTextField';
import Typography from 'components/Typography';
import { Controller, UseFieldArrayPrepend, UseFieldArrayRemove, useFormContext } from 'react-hook-form';

import { STAFF_PROFILE_FORM_TEXT } from '../constants';
import { ProfileUpdateFormType, StaffCreateFormType } from '../types';

type Props = {
  careerFields: StaffCreateFormType['careers'];
  prepend: UseFieldArrayPrepend<StaffCreateFormType, 'careers'> | UseFieldArrayPrepend<ProfileUpdateFormType, 'careers'>;
  remove: UseFieldArrayRemove;
};

const Careers = ({ careerFields, prepend, remove }: Props) => {
  const { control } = useFormContext();

  return (
    <Container>
      <Typography className="sub-title" size={13} weight={500} textColor="gray2">
        {STAFF_PROFILE_FORM_TEXT.subTitle.careers}
      </Typography>

      <ul className="careers">
        {careerFields.length ? (
          careerFields.map((field, index) => {
            return (
              <li className="career" key={field.id}>
                <Controller
                  control={control}
                  name={`careers.${index}.career`}
                  render={({ field }) => (
                    <OutlinedTextField placeholder="주요이력 입력" id={field.name} suffix={<></>} {...field} />
                  )}
                />
                <IconButton onClick={() => (index === 0 ? prepend({ career: '' }) : remove(index))}>
                  <Icon name={index === 0 ? 'plusFill' : 'deleteFill'} fillColor={theme.color.gray3} />
                </IconButton>
              </li>
            );
          })
        ) : (
          <li className="career">
            <Controller
              control={control}
              name="careers.0.career"
              render={({ field }) => (
                <OutlinedTextField
                  placeholder="주요이력 입력"
                  id={field.name}
                  suffix={<></>}
                  {...field}
                  value={field.value || ''}
                />
              )}
            />
            <IconButton onClick={() => prepend({ career: '' })}>
              <Icon name="plusFill" fillColor={theme.color.gray3} />
            </IconButton>
          </li>
        )}
      </ul>
    </Container>
  );
};

export default Careers;

const Container = styled.div`
  margin-bottom: 20px;
  width: 100%;

  .sub-title {
    margin: 34px 0 14px;
  }

  .careers {
    ${theme.flex('column', 'center', 'flex-start', 8)};
    padding-bottom: 82px;

    .career {
      ${theme.flex()};
      gap: 8px;
      width: 100%;

      .outlined-text-field {
        flex: 1;
      }
    }
  }
`;
