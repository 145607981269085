/**
 * 권한 목록
 * https://docs.google.com/spreadsheets/d/1xkeXMkO1dYKyw-J4sSNcQAQkPdkycywHGr48QLyTV44/edit#gid=1311959331
 */
export const PERMISSION = {
  facility: { edit: { id: 101310, super: [], type: 1 } }, // 상호, 주소, 연락처 등 시설 정보 수정
  operation: { edit: { id: 101311, super: [], type: 1 } }, // 수업 예약 취소 미리알림 등 운영 정보 설정
  role: { edit: { id: 101312, super: [], type: 1 } }, // 역할별 권한 설정
  staff: {
    view: { id: 101313, super: [], type: 1 }, // 스태프 조회
    create: { id: 101314, super: [101313], type: 1 }, // 스태프 등록
    edit: { id: 101315, super: [101313], type: 1 }, // 스태프 수정
    delete: { id: 101316, super: [101313], type: 1 }, // 스태프 삭제
  },
  room: { edit: { id: 101317, super: [], type: 1 } }, // 룸 추가 수정 삭제
  member: {
    view: { id: 101410, super: [], type: 2 }, // 회원 조회
    create: { id: 101411, super: [101410], type: 2 }, // 회원 등록
    edit: { id: 101412, super: [101410], type: 2 }, // 회원 수정
    delete: { id: 101414, super: [101410], type: 2 }, // 회원 삭제
    viewMobile: { id: 101413, super: [101410], type: 2 }, // 회원 휴대폰 번호 조회
    registerTicket: { id: 101415, super: [101410], type: 2 }, // 회원 수강권 발급
    updateTicket: { id: 101416, super: [101410], type: 2 }, // 회원 수강권 정보 조회 및 수정
    downloadExcel: { id: 101421, super: [101410], type: 2 }, // 회원 엑셀 다운로드
    memo: {
      view: { id: 101430, super: [], type: 2 }, // 회원 메모 조회
      create: { id: 101431, super: [101430], type: 2 }, // 회원 메모 등록
      edit: { id: 101432, super: [101430], type: 2 }, // 회원 메모 수정
      delete: { id: 101433, super: [101430], type: 2 }, // 회원 메모 삭제
    },
  },
  counsel: {
    view: { id: 101420, super: [], type: 2 }, // 상담 고객 조회
    create: { id: 101417, super: [101420], type: 2 }, // 상담 고객 등록
    edit: { id: 101418, super: [101420], type: 2 }, // 상담 고객 수정
    delete: { id: 101419, super: [101420], type: 2 }, // 상담 고객 삭제
  },
  ticket: {
    create: { id: 101510, super: [], type: 3 }, // 수강권 등록
    edit: { id: 101511, super: [], type: 3 }, // 수강권 수정
    manage: { id: 101512, super: [], type: 3 }, // 수강권 판매 정지 및 판매 재개
  },
  sales: {
    view: { id: 101513, super: [], type: 3 }, // 매출 조회
  },
  lecture: {
    mine: {
      create: { group: { id: 101207, super: [], type: 4 }, private: { id: 101211, super: [], type: 4 } }, // 본인 수업 등록
      editLecture: { group: { id: 101208, super: [], type: 4 }, private: { id: 101212, super: [], type: 4 } }, // 본인 수업 수정
      editBookings: { group: { id: 101209, super: [], type: 4 }, private: { id: 101213, super: [], type: 4 } }, // 본인 수업 예약 변경
      cancelBookings: { group: { id: 101225, super: [], type: 4 }, private: { id: 101226, super: [], type: 4 } }, // 본인 수업 예약 취소
      deleteLecture: { group: { id: 101210, super: [], type: 4 }, private: { id: 101214, super: [], type: 4 } }, // 본인 수업 삭제
      updateMemo: { group: { id: 101252, super: [], type: 4 }, private: { id: 101253, super: [], type: 4 } }, // 본인의 메모 등록, 수정, 삭제
      createPast: { group: { id: 101229, super: [], type: 4 }, private: { id: 101234, super: [], type: 4 } }, // 본인 과거 수업 등록
      editPastLecture: { group: { id: 101230, super: [], type: 4 }, private: { id: 101235, super: [], type: 4 } }, // 본인 과거 수업 수정
      editPastBookings: { group: { id: 101231, super: [], type: 4 }, private: { id: 101236, super: [], type: 4 } }, // 본인 과거 수업 예약 변경
      cancelPastBookings: { group: { id: 101232, super: [], type: 4 }, private: { id: 101237, super: [], type: 4 } }, // 본인 과거 수업 예약 취소
      deletePastLecture: { group: { id: 101233, super: [], type: 4 }, private: { id: 101238, super: [], type: 4 } }, // 본인 과거 수업 삭제
    },
    others: {
      view: { group: { id: 101216, super: [], type: 4 }, private: { id: 101221, super: [], type: 4 } }, // 다른 스태프 수업 조회
      create: { group: { id: 101215, super: [101216], type: 4 }, private: { id: 101220, super: [101221], type: 4 } }, // 다른 스태프 수업 등록
      editLecture: { group: { id: 101217, super: [101216], type: 4 }, private: { id: 101222, super: [101221], type: 4 } }, // 다른 스태프 수업 수정
      editBookings: { group: { id: 101218, super: [101216], type: 4 }, private: { id: 101223, super: [101221], type: 4 } }, // 다른 스태프 수업 예약 변경
      cancelBookings: { group: { id: 101227, super: [101216], type: 4 }, private: { id: 101228, super: [101221], type: 4 } }, // 다른 스태프 수업 예약 취소
      deleteLecture: { group: { id: 101219, super: [101216], type: 4 }, private: { id: 101224, super: [101221], type: 4 } }, // 다른 스태프 수업 삭제
      viewMemo: { group: { id: 101254, super: [], type: 4 }, private: { id: 101256, super: [], type: 4 } }, // 다른 스태프 메모 조회
      updateMemo: { group: { id: 101255, super: [101254], type: 4 }, private: { id: 101257, super: [101256], type: 4 } }, // 다른 스태프 메모 등록, 수정, 삭제
      createPast: { group: { id: 101239, super: [101216], type: 4 }, private: { id: 101244, super: [101221], type: 4 } }, // 다른 스태프 과거 수업 등록
      editPastLecture: { group: { id: 101240, super: [101216], type: 4 }, private: { id: 101245, super: [101221], type: 4 } }, // 다른 스태프 과거 수업 수정
      editPastBookings: { group: { id: 101241, super: [101216], type: 4 }, private: { id: 101246, super: [101221], type: 4 } }, // 다른 스태프 과거 수업 예약 변경
      cancelPastBookings: { group: { id: 101242, super: [101216], type: 4 }, private: { id: 101247, super: [101221], type: 4 } }, // 다른 스태프 과거 수업 예약 취소
      deletePastLecture: { group: { id: 101243, super: [101216], type: 4 }, private: { id: 101248, super: [101221], type: 4 } }, // 다른 스태프 과거 수업 삭제
    },
  },
  etcSchedule: {
    mine: {
      create: { id: 101249, super: [], type: 4 }, // 본인 기타 일정 등록
      edit: { id: 101250, super: [], type: 4 }, // 본인 기타 일정 수정
      delete: { id: 101251, super: [], type: 4 }, // 본인 기타 일정 삭제
    },
  },
  board: {
    notice: {
      view: { id: 101610, super: [], type: 5 }, // 공지사항 조회
      create: { id: 101611, super: [101610], type: 5 }, // 공지사항 등록 수정
      delete: { id: 101612, super: [101610], type: 5 }, // 공지사항 삭제
    },
    qna: {
      view: { id: 101620, super: [], type: 5 }, // Q&A 조회
      comment: { id: 101621, super: [101620], type: 5 }, // Q&A 댓글 등록 수정 삭제
      deleteOthers: { id: 101622, super: [101620], type: 5 }, // Q&A 다른 스태프의 댓글 삭제
    },
  },
  message: {
    sms: {
      view: { id: 101637, super: [], type: 6 }, // 문자 메세지 조회
      send: { id: 101638, super: [101637], type: 6 }, // 문자 메세지 발송
      edit: { id: 101639, super: [101637], type: 6 }, // 문자 메세지 수정, 예약 메세지 취소
      delete: { id: 101640, super: [101637], type: 6 }, // 문자 메세지 삭제
    },
    push: {
      view: { id: 101641, super: [], type: 6 }, // 앱 푸시 메세지 조회
      send: { id: 101642, super: [101641], type: 6 }, // 앱 푸시 메세지 발송
      edit: { id: 101643, super: [101641], type: 6 }, // 앱 푸시 메세지 수정, 예약 메세지 취소
      delete: { id: 101644, super: [101641], type: 6 }, // 앱 푸시 메세지 삭제
    },
  },
};
