import DayTimePicker, { DayTimePickerProps } from 'designedComponents/Pickers/DayTimePicker';
import RuleTypeAccordion from 'pages/Booking/components/AvailableTime/RuleType/RuleTypeAccordion';
import { RuleTypeContainer } from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BOOKING_AVAILABLE_TIME_SETTING_TITLE } from 'pages/Booking/constants';
import { SmallTitleTypography } from 'pages/Booking/Group/Form/components/Operation/Create/OthersAvailableSetting';
import { RuleTypeCommonProps } from 'pages/Booking/types';
import { numToTimeColon } from 'pages/Booking/utils/convertTimes';

import ARTypeAccordionHeader from './header/ARTypeAccordionHeader';

type Props = RuleTypeCommonProps & {
  startDays: number;
  startTime: number;
  endDays: number;
  endTime: number;
  changeDate: DayTimePickerProps['onChange'];
};

/** 수업일 기준 - 시작시간부터 마감시간까지  */
const ARType = ({ iconName, isOpen, onToggle, textType, startDays, startTime, endDays, endTime, changeDate }: Props) => {
  return (
    <RuleTypeContainer className={`rule-type AR ${textType}`}>
      <RuleTypeAccordion
        iconName={iconName}
        isOpen={isOpen}
        onChange={() => onToggle(!isOpen)}
        height={80}
        isLast={textType !== 'booking'}
        noBottomBorder={textType === 'booking'}
        header={
          <div>
            <SmallTitleTypography>{BOOKING_AVAILABLE_TIME_SETTING_TITLE[textType]}</SmallTitleTypography>
            <ARTypeAccordionHeader
              isOpen={isOpen}
              textType={textType}
              startDays={startDays}
              startTime={startTime}
              endDays={endDays}
              endTime={endTime}
            />
          </div>
        }>
        <DayTimePicker
          startValues={{ day: startDays, time: numToTimeColon(startTime) }}
          endValues={{ day: endDays, time: numToTimeColon(endTime) }}
          onChange={changeDate}
        />
      </RuleTypeAccordion>
    </RuleTypeContainer>
  );
};

export default ARType;
