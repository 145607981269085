import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player';
/** Lottie 애니메이션 json 파일 경로  */
import { theme } from 'assets/styles';
import { useLocation } from 'react-router-dom';

import { LOADINGS } from './constants';

type Props = {
  type?: keyof typeof LOADINGS;
  loop?: IPlayerProps['loop'];
};

const Loading = ({ type = 'circles', loop = true }: Props) => {
  const { pathname } = useLocation();

  return (
    <Container
      className="loading"
      width={LOADINGS[type].width}
      isScheduleLoading={type === 'refresh' && pathname === '/schedule'}>
      <Player src={LOADINGS[type].src} loop={loop} autoplay />
    </Container>
  );
};

export default Loading;

const Container = styled.div<{ width: number; isScheduleLoading: boolean }>(
  ({ width }) => css`
    ${theme.flex('', 'center', 'center')};

    .lf-player-container {
      width: ${width}px;
      height: ${width}px;
    }
  `,

  /** 일정 페이지 전용 로딩 스피너 */
  ({ isScheduleLoading }) =>
    isScheduleLoading &&
    css`
      margin: 24px 0;

      .lf-player-container {
        svg {
          path[fill='rgb(255,255,255)'] {
            opacity: 0.16;
          }

          path:last-of-type {
            fill: ${theme.color.white};
            stroke: none;
          }
        }
      }
    `,
);
