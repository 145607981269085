import { memo, useEffect } from 'react';
import { postMessageToApp } from 'utils/communicateWithNative';
import { recordErrorWithSentry } from 'utils/sentry';

import { URL_REGEX } from './TextWithURL';

type Props = {
  htmlText: string;
  className?: string;
};

/**
 * 스튜디오공지사항처럼 어드민에 이미 적용된 html 텍스트로 전달되었을 때
 * 외부링크 변환해서 보여주기 위한 컴포넌트
 */
const HtmlWithURL = memo(({ htmlText, className }: Props) => {
  const urlWithHTTP = (url: string) => (url.startsWith('http') ? url : `https://${url}`);
  const transformUrls = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    try {
      // a태그면 속성만 추가
      doc.querySelectorAll('a').forEach(element => {
        element.setAttribute('id', 'out-link');
        element.setAttribute('target', '_blank');
        element.setAttribute('rel', 'noopener noreferrer');
        element.href = urlWithHTTP(element.href);
      });

      // 텍스트는 URL를 a태그로 감싸기
      doc.querySelectorAll('p,span,b,em,i').forEach(element => {
        element.innerHTML = element.innerHTML.replace(
          URL_REGEX,
          url => `<a id="out-link" href="${urlWithHTTP(url)}" target="_blank" rel="noopener noreferrer">${url}</a>`,
        );
      });
    } catch (e) {
      console.error(e);
      recordErrorWithSentry(e);
    }
    return doc.body.innerHTML.trim();
  };

  const anchorTagAppendedText = transformUrls(htmlText);

  useEffect(() => {
    const anchors: HTMLAnchorElement[] = Array.from(document.querySelectorAll('#out-link'));
    const anchorClickEvents = anchors.map(
      anchor =>
        function (e: MouseEvent) {
          // 웹에서는 기본동작
          if (!window.ReactNativeWebView) return;

          // 웹뷰에서는 앱으로 메시지 전달해서 외부링크 열리도록
          e.preventDefault();
          e.stopPropagation();
          const href = anchor.getAttribute('href') || '';
          postMessageToApp('OPEN_URL', urlWithHTTP(href));
        },
    );

    // 이벤트 등록
    anchorClickEvents.forEach((event, index) => {
      anchors[index].addEventListener('click', event);
    });

    return () => {
      // 이벤트 제거
      anchorClickEvents.forEach((event, index) => {
        anchors[index].removeEventListener('click', event);
      });
    };
  });

  return <div className={className} dangerouslySetInnerHTML={{ __html: anchorTagAppendedText }} />;
});

HtmlWithURL.displayName = 'HtmlWithURL';

export default HtmlWithURL;
