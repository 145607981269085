import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import usePatchStaffProfile from 'hooks/service/mutations/usePatchStaffProfile';
import usePostFileUploadAvatarPresignedUrl from 'hooks/service/mutations/usePostFileUploadAvatarPresignedUrl';
import usePermission from 'hooks/usePermission';
import usePhoto from 'hooks/usePhoto';
import usePopup from 'hooks/usePopup';
import { ChangeEvent, memo, useCallback, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { isImageLoadingAtom } from 'recoil/avatar';
import { staffIdAtom } from 'recoil/common';
import CenterLineLoading from 'sharedComponents/CenterLineLoading';
import ColorChip from 'sharedComponents/ColorChip';
import InputFile from 'sharedComponents/InputFile';
import ProfileImgExtension from 'sharedComponents/ProfileField/components/ProfileImgExtension';
import { StaffType } from 'types/staffTypes';
import filters from 'utils/filters';
import getColor, { Color } from 'utils/getColor';
import getImageUrl from 'utils/getImageUrl';

import BirthdaySection from './BirthdaySection';

type Props = {
  staff: StaffType;
  isStaffFetching: boolean;
};

const StaffProfileCard = ({ staff, isStaffFetching }: Props) => {
  const { id, name, mobile, account, profile, roles, avatar } = staff;

  const fileRef = useRef<HTMLInputElement>(null);
  const isImageLoading = useRecoilValue(isImageLoadingAtom);
  const loginStaffId = useRecoilValue(staffIdAtom);

  const { setPopup } = usePopup();
  const { onAddPhoto, onChange } = usePhoto(fileRef, { isProfile: true });
  const { getAvatarImage, isPending: isBeforePending } = usePostFileUploadAvatarPresignedUrl();
  const { mutate: patchStaffProfileMutate, isPending } = usePatchStaffProfile({ targetStaffId: id });

  const { hasPermission } = usePermission();

  const updateStaffProfile = useCallback(
    (image: string | null) => {
      const params = { staffAvatar: { image } };
      if (image === null) return;
      patchStaffProfileMutate(params);
    },
    [patchStaffProfileMutate],
  );

  const changeProfile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || !e.target.files[0]) return; // 파일이 없을 경우 중단

      const file = e.target.files[0];
      if (onChange(e) === false) return; // 이미지 크기 및 확장자 오류시 중단
      const avatarResponse = await getAvatarImage(file);
      if (avatarResponse === false) return; // 이미지 업로드 실패시 중단
      updateStaffProfile(avatarResponse ?? null);
    },
    [getAvatarImage, onChange, updateStaffProfile],
  );

  const formatRegisteredAt = useMemo(() => {
    return `${roles[0].display_name}ㆍ${filters.date(profile.registered_at)} 등록`;
  }, [roles, profile]);

  const clickProfileImage = () => {
    setPopup(<ProfileImgExtension image={avatar ? avatar.image : null} />);
  };

  return (
    <Container>
      {(isImageLoading || isStaffFetching || isBeforePending || isPending) && <CenterLineLoading />}

      <section className="profile-top">
        <div className="avatar-wrapper">
          <div onClick={clickProfileImage}>
            <Avatar size={72} imgUrl={getImageUrl(avatar ? avatar.image : null)} />
          </div>
          {(loginStaffId === id || hasPermission(PERMISSION.staff.edit)) && (
            <IconButton
              variant="outlined"
              widthSize={24}
              borderRadius="50%"
              bgColor="white"
              outlineColor="gray6"
              onClick={onAddPhoto}>
              <Icon name="camera" size={12} />
            </IconButton>
          )}
          <InputFile ref={fileRef} onChange={changeProfile} single />
        </div>

        <div className="profile-info-wrapper">
          <div className="profile-name">
            <Typography size={19} weight={700}>
              {name}
            </Typography>
          </div>

          <Typography className="mobile-area" size={15} textColor="gray2">
            {filters.mobile(mobile) ? (
              <>
                {filters.mobile(mobile)}
                {!account && <Icon name="connectOff" size={16} fillColor={theme.color.gray3} />}
              </>
            ) : (
              '-'
            )}
          </Typography>

          <div className="role">
            <ColorChip size={8} fillColor={color[getColor(profile.representative_color) as Color]} />
            <Typography size={13} textColor="gray2" opacity={0.8}>
              {formatRegisteredAt}
            </Typography>
          </div>
        </div>
      </section>

      {(profile.birthday || profile.gender) && (
        <BirthdaySection birthday={profile.birthday ? filters.date(profile.birthday) : null} gender={profile.gender} />
      )}
    </Container>
  );
};

export default memo(StaffProfileCard);

const Container = styled.div`
  background-color: ${theme.color.white};

  .profile-top {
    ${theme.flex('row', 'center', '', 16)}
    padding: 16px 20px 20px;

    .avatar-wrapper {
      position: relative;
      width: max-content;

      .icon-button {
        position: absolute;
        bottom: 0;
        right: 0;

        &::after {
          content: '';
          position: absolute;
          inset: -4px;
        }
      }
    }

    .profile-info-wrapper {
      .profile-name {
        ${theme.flex('row', 'center', '', 2)}
      }

      .mobile-area {
        ${theme.flex('row', 'center', 'flex-start')}
      }

      .role {
        ${theme.flex('row', 'center', '', 6)};
        margin-top: 4px;
      }
    }
  }
`;
