import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import usePatchStaffEventTime, { StaffEventTimeParams } from 'hooks/service/mutations/usePatchStaffEventTime';
import useErrorDialog from 'hooks/useErrorDialog';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import { isEqual, omit, uniqWith } from 'lodash';
import { STAFF_PROFILE_FORM_TEXT } from 'pages/MoreDetails/Staff/Form/constants';
import EventScheduleUpdateFormSingle from 'pages/MoreDetails/Staff/Form/EventScheduleUpdate/components/EventScheduleUpdateFormSingle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { eventScheduleSelectDateAtom } from 'recoil/staff';
import { CLOSED_DAY_TIME } from 'sharedComponents/ProfileField/components/WorkTime/constants';
import filters from 'utils/filters';
import getReverseEventTimeDataset from 'utils/getReverseEventTimeDataset';

import { DrawerButtonPropsType, ScheduleFormType } from '../types';
import { getEventSingleIsPeriodCheck } from '../utils';
import EventRestTime from './EventRestTime';
import EventScheduleDialog from './EventScheduleDialog';
import EventScheduleDropdown from './EventScheduleDropdown';

type Props = {
  workTime: ScheduleFormType;
  /**
   * 삭제, 수정 등의 요청을 위한 전체 리스트
   * id로 삭제, 수정하는 방식이 아닌, 프론트에서 수정 후 해당 데이터를 수정/삭제 후 전체 리스트를 보내는 방식
   */
  dataset: ScheduleFormType[];
};

const EventScheduleCard = ({ workTime, dataset }: Props) => {
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  /** Drawer의 실제로 열고 닫히는 시점 조절을 위한 트리거(isOpenDrawer로는 트랜지션이 끊김) */
  const [isCreateBeforeDrawer, setIsCreateBeforeDrawer] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  /** 저장하기 전까지는 임시필드값으로 활용 */
  const [workTimeField, setWorkTimeField] = useState(workTime);
  const initialClosedDay = workTime.start_time === CLOSED_DAY_TIME.start && workTime.end_time === CLOSED_DAY_TIME.end;
  const [isFieldClosedDay, setIsFieldClosedDay] = useState(initialClosedDay);

  const setSelectDateTexts = useSetRecoilState(eventScheduleSelectDateAtom);

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  useEffect(() => {
    setWorkTimeField(workTime);
  }, [workTime]);

  useEffect(() => {
    if (isOpenDrawer) {
      setIsCreateBeforeDrawer(true);
    } else {
      setTimeout(() => {
        setIsCreateBeforeDrawer(false);
      }, 200);
    }
  }, [isOpenDrawer]);

  const workTimePeriod = filters.timePeriodForOnlyTime(workTime.start_time, workTime.end_time);

  const targetStaffId = useParamsId();
  const { mutate: patchStaffEventTimeMutate } = usePatchStaffEventTime({ targetStaffId });

  const dropdownAction = (type: 'update' | 'remove') => {
    if (type === 'update') {
      setSelectDateTexts([dayjs(workTime.date).toDate()]);
      setIsOpenDrawer(true);
    } else {
      setIsOpenDeleteDialog(true);
    }
  };

  const deleteSubmit = () => {
    const deletedDataset = dataset.filter(data => data.id !== workTime.id);
    const reversedDataset = getReverseEventTimeDataset(deletedDataset);

    const params = {
      schedules: reversedDataset,
    };

    patchStaffEventTimeMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: '특정일 근무시간이 삭제되었습니다.' });
      },
    });
  };

  const onClose = useCallback(() => {
    setIsOpenDrawer(false);
    setIsAccordionOpen(false);
  }, []);

  const onCancel = useCallback(() => {
    setWorkTimeField(workTime);
    setIsFieldClosedDay(initialClosedDay);
    onClose();
  }, [onClose, workTime, initialClosedDay]);

  const updateSubmit = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();

      const isEventPeriodCheck = getEventSingleIsPeriodCheck(workTimeField);
      if (!isEventPeriodCheck) {
        errorDialog.open('휴식시간은 근무시간을\n초과할 수 없습니다.');
        return;
      }

      const updatedDataset: ScheduleFormType[] = dataset.map(data => {
        return data.id === workTimeField.id ? workTimeField : data;
      });

      const reversedDataset = getReverseEventTimeDataset(updatedDataset).map(data => {
        return omit(data, 'id');
      });

      const params: StaffEventTimeParams = {
        schedules: uniqWith(reversedDataset, isEqual),
      };

      patchStaffEventTimeMutate(params, {
        onSuccess: () => {
          if (isFieldClosedDay) {
            setWorkTimeField(omit(workTimeField, 'restTimes'));
          }
          setToast({ type: 'success', message: STAFF_PROFILE_FORM_TEXT.success.eventSchedulesUpdate });
          onClose();
        },
      });
    },
    [dataset, isFieldClosedDay, workTimeField, onClose, patchStaffEventTimeMutate, setToast, errorDialog],
  );

  const drawerButtonProps: DrawerButtonPropsType = useMemo(() => {
    return {
      leftButton: {
        text: '취소',
        variant: 'outlined',
        size: 'large56',
        onClick: onCancel,
        fullWidth: true,
      },
      rightButton: {
        text: '수정 완료',
        type: 'submit',
        variant: 'contained',
        color: 'point',
        size: 'large56',
        onClick: updateSubmit,
        fullWidth: true,
        disabled: isEqual(workTime, workTimeField),
      },
    };
  }, [onCancel, workTime, updateSubmit, workTimeField]);

  return (
    <Container isOpenDropDown={isOpenDropDown}>
      <div className="card-header">
        <Typography size={15} weight={600}>
          {dayjs(workTime.date).format('YYYY.MM.DD (ddd)')}
        </Typography>

        <EventScheduleDropdown isOpen={isOpenDropDown} setIsOpen={setIsOpenDropDown} onClick={dropdownAction}>
          <IconButton onClick={() => setIsOpenDropDown(!isOpenDropDown)}>
            <Icon name="more" fillColor={theme.color.gray2} />
          </IconButton>
        </EventScheduleDropdown>
      </div>

      <div className="card-content">
        <div className="schedule-work-time">
          {initialClosedDay ? (
            <Typography size={15} textColor="secondary3" opacity={0.8}>
              휴무일
            </Typography>
          ) : (
            <>
              <Typography size={15} textColor="gray2">
                근무시간
              </Typography>
              <Typography size={15} textColor="gray2">
                {workTimePeriod}
              </Typography>
            </>
          )}
        </div>

        {!initialClosedDay && workTime.restTimes && <EventRestTime restTimes={workTime.restTimes} />}

        <EventScheduleDialog
          isOpen={isOpenDeleteDialog}
          onClose={() => setIsOpenDeleteDialog(false)}
          deleteSubmit={deleteSubmit}
        />

        {isCreateBeforeDrawer && (
          <StyledDoubleButtonDrawer isOpen={isOpenDrawer} onClose={onCancel} {...drawerButtonProps} hideHandle>
            <EventScheduleUpdateFormSingle
              workTimeField={workTimeField}
              setWorkTimeField={setWorkTimeField}
              isFieldClosedDay={isFieldClosedDay}
              setIsFieldClosedDay={setIsFieldClosedDay}
              isAccordionOpen={isAccordionOpen}
              setIsAccordionOpen={setIsAccordionOpen}
            />
          </StyledDoubleButtonDrawer>
        )}
      </div>
    </Container>
  );
};

export default EventScheduleCard;

const Container = styled.li<{ isOpenDropDown: boolean }>`
  padding: 18px 20px;

  .card-header {
    ${theme.flex('row', 'center', 'space-between')};
    margin-bottom: 6px;
  }

  .card-content {
    .schedule-work-time,
    .schedule-rest-time {
      ${theme.flex('row', 'flex-start', 'flex-start', 16)};
    }

    .schedule-work-time {
      margin-bottom: 2px;
    }

    .schedule-rest-time {
      .times {
        ${theme.flex('column')};
        gap: 2px;
      }
    }
  }

  .modify-dropdown {
    ${({ isOpenDropDown }) => !isOpenDropDown && 'z-index: 0'};
  }
`;

/** DoubleButtonDrawer 커스텀 스타일 */
const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  padding: 24px 0 calc(64px + 76px);
`;
