import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { Z_INDEX } from 'constants/zIndex';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';

type Props = {
  buttonText?: string;
  cancelClick?: () => void;
  onClick?: () => void;
  single?: boolean;
  disabled?: boolean;
};

const SubmitButtonGroup = ({ buttonText, cancelClick, onClick, single, disabled }: Props) => {
  const navigate = useNavigate();

  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);

  return (
    <ButtonWrapper isKeyboardShow={isKeyboardShow}>
      {
        <ButtonGroup leftButtonWidth={115}>
          {!single && (
            <Button
              variant="contained"
              color="gray"
              size="large56"
              onClick={() => {
                if (cancelClick) cancelClick();
                else navigate(-1);
              }}>
              이전
            </Button>
          )}
          <Button
            type="submit"
            size="large56"
            variant="contained"
            color="point"
            fullWidth
            onClick={() => onClick?.()}
            disabled={disabled}>
            {buttonText}
          </Button>
        </ButtonGroup>
      }
    </ButtonWrapper>
  );
};

export default SubmitButtonGroup;

const ButtonWrapper = styled.div<{ isKeyboardShow: boolean }>`
  width: 100%;
  background-color: ${theme.color.white};
  z-index: ${Z_INDEX.footerButton};

  ${({ isKeyboardShow }) =>
    !isKeyboardShow &&
    `
    position: fixed;
    bottom: 0;
    padding: 16px 20px 10px;
  `}
`;
