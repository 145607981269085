import { useMutation } from '@tanstack/react-query';
import { SuperLoginType } from 'pages/SuperLogin/type';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostAuthSuperLogin = () => {
  const { mutationFn } = useMutationFnWithCommonParams<AuthSuperLoginParams, AuthSuperLoginResponse>({
    method: `post`,
    url: `/api/auth/super-login`,
    data: {
      staff_id: undefined,
      studio_id: undefined,
    },
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostAuthSuperLogin;

type AuthSuperLoginParams = SuperLoginType;
type AuthSuperLoginResponse = {
  // 일반 로그인과 동일한 응답
  account_token: string;
  is_name_nickname: boolean;
};
