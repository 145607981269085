// 공지사항, 문의 등 외부 링크 허용 및 자동 변환
// 회원웹 v2의 UrlToLink.tsx와 동일한 기능

import { MouseEvent } from 'react';
import { postMessageToApp } from 'utils/communicateWithNative';

// 회원웹 정규식 기준, html 태그도 고려하도록 살짝 수정함
export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s<]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s<]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s<]{2,}|www\.[a-zA-Z0-9]+\.[^\s<]{2,})(?![^<]*>)/;

type Props = {
  text?: string;
};
const TextWithURL = ({ text }: Props) => {
  const openURL = (e: MouseEvent<HTMLAnchorElement>) => {
    // 웹에서는 기본동작
    if (!window.ReactNativeWebView) return;

    // 웹뷰에서는 앱으로 메시지 전달해서 외부링크 열리도록
    e.preventDefault();
    e.stopPropagation();
    postMessageToApp('OPEN_URL', e.currentTarget.href);
  };

  const splittedTextArray = text?.split(URL_REGEX) || [];

  return (
    <>
      {splittedTextArray.map(splittedText =>
        splittedText.match(URL_REGEX) ? (
          <a
            key={splittedText}
            onClick={e => openURL(e)}
            href={splittedText.startsWith('http') ? splittedText : `https://${splittedText}`}
            target="_blank"
            rel="noopener noreferrer">
            {splittedText}
          </a>
        ) : (
          splittedText
        ),
      )}
    </>
  );
};

export default TextWithURL;
