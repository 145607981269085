import { LecturePolicyResponse } from 'hooks/service/queries/useGetLecturePolicy';

import { BookingGroupCreateFormType } from '../../Group/types';

const ADD_12_HOURS = 12 * 60;

const convertBookingGroupPolicyFormState = (policy: LecturePolicyResponse): BookingGroupCreateFormType['policy'] => {
  const {
    enter_start_deadline,
    enter_end_deadline,
    daily_booking_change_deadline,
    autoclose_deadline,
    booking_auto_shift_deadline,
    group_booking_rule_type,
    group_booking_startline,
    group_booking_deadline,
    group_booking_start_time,
    group_booking_end_time,
    group_booking_start_days,
    group_booking_end_days,
    group_booking_cancel_rule_type,
    group_booking_cancel_startline,
    group_booking_cancel_deadline,
    group_booking_cancel_start_time,
    group_booking_cancel_end_time,
    group_booking_cancel_start_days,
    group_booking_cancel_end_days,
  } = policy;

  return {
    booking: {
      ruleType: group_booking_rule_type,
      startline:
        group_booking_startline < group_booking_deadline ? group_booking_deadline + ADD_12_HOURS : group_booking_startline, // 기존에 저장된 시작시간이 없거나 시작시간 값이 종료시간보다 적으면 종료시간보다 12시간 많도록 (시설웹 동일 로직)
      deadline: group_booking_deadline,
      startDays: group_booking_start_days,
      endDays: group_booking_end_days,
      startTime: group_booking_start_time,
      endTime: group_booking_end_time,
    },
    bookingCancel: {
      ruleType: group_booking_cancel_rule_type,
      startline:
        group_booking_cancel_startline < group_booking_cancel_deadline
          ? group_booking_cancel_deadline + ADD_12_HOURS
          : group_booking_cancel_startline,
      deadline: group_booking_cancel_deadline,
      startDays: group_booking_cancel_start_days,
      endDays: group_booking_cancel_end_days,
      startTime: group_booking_cancel_start_time,
      endTime: group_booking_cancel_end_time,
    },
    dailyBookingChangeDeadline: daily_booking_change_deadline ?? 0,
    autocloseDeadline: autoclose_deadline ?? 0,
    bookingAutoShiftDeadline: booking_auto_shift_deadline ?? 0,
    enterStartline: enter_start_deadline ?? 0,
    enterDeadline: enter_end_deadline ?? 0,
  };
};

export default convertBookingGroupPolicyFormState;
