import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';

type Props = {
  direction: 'left' | 'right';
  action: () => void;
  disabled: boolean;
};

const PaginateArrow = ({ direction, action, disabled }: Props) => {
  return (
    <StyledIconButton widthSize={28} borderRadius={999} onClick={action} disabled={disabled}>
      <Icon name={direction === 'left' ? 'arrowLeftBold' : 'arrowRightBold'} size={16} fillColor={theme.color.gray2} />
    </StyledIconButton>
  );
};

export default PaginateArrow;

const StyledIconButton = styled(IconButton)`
  border: 1px solid ${theme.color.gray4};

  &:disabled {
    border-color: ${theme.color.gray5};

    svg {
      color: ${theme.color.gray5} !important;
    }
  }
`;
