import styled from '@emotion/styled';
import Textarea from 'components/Textarea';
import { Controller, useFormContext } from 'react-hook-form';

import { STAFF_PROFILE_FORM_TEXT } from '../constants';

const SelfIntroduction = () => {
  const { control } = useFormContext();

  return (
    <Container>
      <Controller
        name="profile.self_introduction"
        control={control}
        render={({ field }) => {
          return (
            <Textarea
              placeholder="자기소개 입력"
              label={STAFF_PROFILE_FORM_TEXT.subTitle.selfIntroduction}
              height={164}
              {...field}
            />
          );
        }}
      />
    </Container>
  );
};

export default SelfIntroduction;

const Container = styled.div`
  width: 100%;
`;
