import { useFieldArray, useFormContext } from 'react-hook-form';

import StaffProfileForm from '../../components/StaffProfileForm';
import { StaffCreateFormType } from '../../types';

type Props = {
  nextStep: () => void;
};

const CreateStepProfile = ({ nextStep }: Props) => {
  const { control } = useFormContext<StaffCreateFormType>();

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'careers',
  });

  return <StaffProfileForm onSubmit={() => null} careerFields={fields} prepend={prepend} remove={remove} nextStep={nextStep} />;
};

export default CreateStepProfile;
