import { useQueryClient } from '@tanstack/react-query';
import { resetRecoilState } from 'constants/resetRecoilState';
import usePostAuthSuperLogin from 'hooks/service/mutations/usePostAuthSuperLogin';
import useQueryString from 'hooks/useQueryString';
import useResetAllRecoilState from 'hooks/useResetAllRecoilState';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { accessTokenAtom } from 'recoil/common';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';
import localStorage from 'utils/localStorage';

import { SuperLoginType } from './type';

const SuperLogin = () => {
  const { getSearchParams } = useQueryString();

  const setToken = useSetRecoilState(accessTokenAtom);
  const navigate = useNavigate();
  const { mutate } = usePostAuthSuperLogin();

  // 어드민에서 받아온 쿼리스트링 정보
  const userId = getSearchParams('userId');
  const token = getSearchParams('token');

  const queryClient = useQueryClient();
  const resetRecoilStates = useResetAllRecoilState(resetRecoilState);

  useEffect(() => {
    // 어드민에서 받아온 정보 없을 경우 일반 로그인해야
    if (!userId || !token) {
      navigate('/login');
      return;
    }
    const params: SuperLoginType = {
      superLogin: 1,
      userId: Number(userId),
      token,
    };

    // 기존 로그인된 상태 초기화
    localStorage.clear();
    queryClient.clear();
    resetRecoilStates();

    mutate(params, {
      onSuccess: ({ data }) => {
        const { account_token: accessToken, is_name_nickname: isUnifiedAccount } = data;
        localStorage.set('access_token', accessToken);
        localStorage.set('tutorialShow', true);
        localStorage.set('onboardingShow', true);
        localStorage.set('isUnifiedAccount', isUnifiedAccount);
        setToken(accessToken);

        const url = isUnifiedAccount ? '/select-studio' : '/unified-account?step=2';
        navigate(url, { state: { type: 'loginSuccess' } });
      },
      onError: () => {
        navigate('/login');
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, token]);
  return <FullScreenLoading loop={false} />;
};

export default SuperLogin;
