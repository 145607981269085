import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import useQueryString from 'hooks/useQueryString';

import PaginateArrow from './PaginateArrow';

type Props = {
  max: number;
};

const Pagination = ({ max }: Props) => {
  const { getSearchParams, setSearchParams } = useQueryString();
  const currentPage = Number(getSearchParams('page')) || 1;

  const scrollToBottom = () => {
    const target = document.getElementById('scrollableTarget') as HTMLElement;
    target.scrollTo({ top: 0 });
  };

  const prev = () => {
    if (currentPage === 1) return;
    scrollToBottom();
    setSearchParams({ page: String(currentPage - 1) }, { replace: true });
  };

  const next = () => {
    if (currentPage === max) return;
    scrollToBottom();
    setSearchParams({ page: String(currentPage + 1) }, { replace: true });
  };

  const getVisiblePageNumbers = () => {
    const sidePageCount = 2;
    const totalVisible = sidePageCount * 2 + 1;
    const start = Math.max(Math.min(currentPage - sidePageCount, max - totalVisible + 1), 1);
    return Array.from({ length: Math.min(totalVisible, max) }, (_, i) => start + i);
  };

  return (
    <Container>
      <PaginateArrow direction="left" action={prev} disabled={currentPage === 1} />
      <div className="page-numbers">
        {getVisiblePageNumbers().map(page => {
          const isSelected = currentPage === page;
          return (
            <Button
              key={page}
              widthSize={28}
              heightSize={28}
              fontSize={isSelected ? 15 : 14}
              fontWeight={isSelected ? 700 : 500}
              textColor={isSelected ? 'primary' : 'gray3'}
              onClick={() => {
                scrollToBottom();
                setSearchParams({ page: String(page) }, { replace: true });
              }}>
              {page}
            </Button>
          );
        })}
      </div>
      <PaginateArrow direction="right" action={next} disabled={currentPage === max} />
    </Container>
  );
};

export default Pagination;

const Container = styled.div`
  ${theme.flex('row', 'center', 'center', 12)};
  margin-top: 40px;

  .page-numbers {
    ${theme.flex('row', 'center', 'center', 2)};
  }
`;
